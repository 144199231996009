import React, { useEffect, useState } from 'react';
import './DataTablesTemplateTable.scss';
import { Button } from 'reactstrap';
import BootstrapTable, {
    TableChangeState,
    TableChangeType,
} from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import moment from 'moment';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DataLoader from '../../../../../components/DataLoader/DataLoader';
import { useNavigate } from 'react-router-dom';
import {
    DataTableEditorModeEnum,
    EditorModeEnum,
    EditorTypeEnum,
} from '../../ProjectEditorHost/ProjectEditorHost';
import {
    DataTableView,
    DataTable,
    emptyTableView,
    DataTableViewColumn,
    DataTableAndTableViewFlatRow,
    TableRowType,
} from '../../../../../models/DataRequestHub/DataTable';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import Helpers from '../../../../../utils/helper';
import { ExpandedText } from '../../../../DataRequest/ExpandedText/ExpandedText';

export interface DataTemplateTableProps {
    tabNumber: number;
}

export const DataTablesTemplateTable = (props: DataTemplateTableProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [
        isTableDeleteConfirmationPopupVisible,
        setIsTableDeleteConfirmationPopupVisible,
    ] = useState(false);
    const [
        isTableViewDeleteConfirmationPopupVisible,
        setIsTableViewDeleteConfirmationPopupVisible,
    ] = useState(false);
    const [selectedTableTemplateId, setSelectedTableTemplateId] = useState(0);
    const [selectedTableViewTemplateId, setSelectedTableViewTemplateId] =
        useState(0);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [dataTables, setDataTables] = useState<DataTable[]>([]);
    const [tableData, setTableData] = useState<DataTableAndTableViewFlatRow[]>(
        []
    );
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const defaultSortDirection: { dataField: string; order: 'asc' | 'desc' } = {
        dataField: 'dateCreated',
        order: 'desc',
    };
    const tableTooltipTargetId = 'data-and-document-requests-dashboard';
    const [sortingState, setSortingState] = useState({
        data: [],
        page: page,
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    const createDataTableCopy = (dataTableId: number) => {
        const dataTable = getDataTable(dataTableId);
        const templateColumns = dataTable.columns.map((x) => {
            return { ...x, id: 0 };
        });
        const templateViews = dataTable.views.map((x) => {
            return {
                ...x,
                id: 0,
                viewColumns: x.viewColumns.map((y) => {
                    return { ...y, id: 0 };
                }),
            };
        });
        const templateName = dataTable.originalTemplateName + ' - Copy';
        axios
            .post('/api/dataRequestTemplateProject/dataTables', {
                originalTemplateName: templateName,
                columns: templateColumns,
                views: templateViews,
            })
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject();
                } else {
                    createNotification(
                        'An error occured while creating template',
                        'error'
                    );
                }
            });
    };

    const createViewTemplateCopy = (
        dataTableId: number,
        tableViewId: number
    ) => {
        const dataTableView = getDataTableView(dataTableId, tableViewId);
        const columnsCopy = dataTableView.viewColumns.map(
            (viewColumn): DataTableViewColumn => ({ ...viewColumn, id: 0 })
        );
        const viewCopy: DataTableView = {
            id: 0,
            description: dataTableView.description,
            name: `${dataTableView.name} - Copy`,
            viewColumns: columnsCopy,
            dateCreated: new Date(),
            dueDate: null,
            progressStatus: dataTableView.progressStatus,
        };

        axios
            .post(
                `/api/dataRequestTemplateProject/dataTables/${dataTableId}/tableViews`,
                viewCopy
            )
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject();
                } else {
                    createNotification(
                        'An error occured while creating view template copy',
                        'error'
                    );
                }
            });
    };

    const getDataTable = (tableId: number) =>
        dataTables.find((f) => f.id === tableId);
    const getDataTableView = (tableId: number, viewId: number) =>
        getDataTable(tableId).views.find((f) => f.id === viewId);

    const getColumns = () => [
        { dataField: 'rowId', text: '', hidden: true },
        {
            dataField: 'name',
            text: 'Template Name',
            sort: true,
            headerClasses: 'template-name-column',
            formatter: (
                cellContent: string,
                row: DataTableAndTableViewFlatRow
            ) => (
                <ExpandedText
                    content={cellContent}
                    uniqueId={`template-name-${row.rowId}`}
                    heightToShowLinksPx={33}
                    clampLines={2}></ExpandedText>
            ),
        },
        {
            dataField: 'dateCreated',
            text: 'Added On',
            sort: true,
            headerClasses: 'added-on-date-column',
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
        },
        {
            dataField: 'description',
            text: '',
            sort: false,
            formatter: (
                cellContent: string,
                row: DataTableAndTableViewFlatRow
            ) => (
                <ExpandedText
                    content={cellContent}
                    uniqueId={`view-description-${row.rowId}`}
                    heightToShowLinksPx={33}
                    clampLines={2}></ExpandedText>
            ),
        },
        {
            dataField: 'rowId',
            text: 'Action',
            headerClasses: 'action-column',
            formatter: (_: any, row: DataTableAndTableViewFlatRow) => {
                return row.rowType === TableRowType.DataTable
                    ? getTableActionColumns(row)
                    : getViewActionColumns(row);
            },
        },
    ];

    const getViewActionColumns = (row: DataTableAndTableViewFlatRow) => (
        <div className="action-icons">
            <FontAwesomeIcon
                onClick={() => createViewTemplateCopy(row.tableId, row.viewId)}
                icon={faCopy}
            />
            <FontAwesomeIcon
                onClick={() => {
                    navigateToTableViewEditorPage(row.tableId, row.viewId);
                }}
                icon={faEdit}
            />
            <FontAwesomeIcon
                className="delete-button"
                onClick={() => {
                    setSelectedTableTemplateId(row.tableId);
                    setSelectedTableViewTemplateId(row.viewId);
                    setIsTableViewDeleteConfirmationPopupVisible(true);
                }}
                icon={faTrashCan}
            />
        </div>
    );

    const getTableActionColumns = (row: DataTableAndTableViewFlatRow) => (
        <div className="action-icons">
            <FontAwesomeIcon
                onClick={() => navigateToTableViewCreatingPage(row.tableId)}
                icon={faPlusSquare as any}
            />
            <FontAwesomeIcon
                onClick={() => {
                    createDataTableCopy(row.tableId);
                }}
                icon={faCopy}
            />
            <FontAwesomeIcon
                onClick={() => {
                    navigateToTableEditPage(row.tableId);
                }}
                icon={faEdit}
            />
            <FontAwesomeIcon
                className="delete-button"
                onClick={() => {
                    setSelectedTableTemplateId(row.tableId);
                    setIsTableDeleteConfirmationPopupVisible(true);
                }}
                icon={faTrashCan}
            />
        </div>
    );

    useEffect(() => {
        fetchTemplateProject();
    }, [props]);

    useEffect(() => {
        let sortedDataTables: DataTable[] = Helpers.dynamicSorting(
            dataTables,
            sortingState.sortField,
            sortingState.sortOrder
        );

        sortedDataTables = sortedDataTables.map((table) => {
            const sortedViews = Helpers.dynamicSorting(
                table.views,
                sortingState.sortField,
                sortingState.sortOrder
            );

            return {
                ...table,
                views: sortedViews,
            };
        });

        const tableRows = sortedDataTables.flatMap(
            (table: DataTable): DataTableAndTableViewFlatRow[] => {
                const parentTable: DataTableAndTableViewFlatRow = {
                    rowId: `Table-${table.id}`,
                    tableId: table.id,
                    viewId: 0,
                    name: table.name,
                    dateCreated: table.dateCreated,
                    rowType: TableRowType.DataTable,
                };

                const viewsList = table.views.map(
                    (view: DataTableView): DataTableAndTableViewFlatRow => {
                        const result: DataTableAndTableViewFlatRow = {
                            rowId: `View-${view.id}`,
                            tableId: table.id,
                            viewId: view.id,
                            name: view.name,
                            dateCreated: view.dateCreated,
                            description: view.description,
                            rowType: TableRowType.DataTableView,
                        };
                        return result;
                    }
                );
                return [parentTable, ...viewsList];
            }
        );

        setTotalSize(tableRows?.length ?? 0);
        const startIndex = (sortingState.page - 1) * sortingState.sizePerPage;
        const endIndex = startIndex + sortingState.sizePerPage;
        const paginatedRows = tableRows.slice(startIndex, endIndex);

        setTableData(paginatedRows);
    }, [dataTables, sortingState]);

    const fetchTemplateProject = () => {
        axios
            .get(`/api/DataRequestTemplateProject/dataTables`)
            .then((response) => {
                if (response.status === 200) {
                    const dataTableResponse = response.data;
                    setDataTables(dataTableResponse);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleTableChange = (
        _type: TableChangeType,
        newState: TableChangeState<any>
    ) => {
        const newSortState = {
            data: [] as DataTable[],
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };

        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);

        setSortingState(newSortState);
    };

    const removeDataTableTemplate = (tableTemplateId: number) => {
        axios
            .delete(
                `/api/dataRequestTemplateProject/dataTables/${tableTemplateId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject();
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while fetching templates',
                    'error'
                );
            });
    };

    const removeDataTableViewTemplate = (
        tableTemplateId: number,
        tableViewTemplateId: number
    ) => {
        axios
            .delete(
                `/api/dataRequestTemplateProject/dataTables/${tableTemplateId}/tableViews/${tableViewTemplateId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject();
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while fetching templates',
                    'error'
                );
            });
    };

    const navigateToTableViewEditorPage = (tableId: number, viewId: number) => {
        const dataTable = getDataTable(tableId);
        const tableView = getDataTableView(tableId, viewId);
        navigate('/project-editor', {
            state: {
                type: EditorTypeEnum.DataTable,
                dataTableEditorMode: DataTableEditorModeEnum.EditView,
                dataTables: [
                    {
                        id: tableId,
                        name: dataTable.name,
                        originalTemplateName: dataTable.name,
                        columns: dataTable.columns,
                        views: [tableView ?? { ...emptyTableView }],
                    },
                ],
                selectedDataTableId: tableId,
                selectedTableViewId: viewId,
                mode: EditorModeEnum.EditTempalte,
                additionalUrlToReturn: `?tab=${props.tabNumber}`,
            },
        });
    };

    const navigateToTableEditPage = (tableId: number) => {
        const dataTable = getDataTable(tableId);
        navigate('/project-editor', {
            state: {
                selectedDataTableId: tableId,
                dataTables: [dataTable],
                mode: EditorModeEnum.EditTempalte,
                type: EditorTypeEnum.DataTable,
                additionalUrlToReturn: `?tab=${props.tabNumber}`,
            },
        });
    };

    const navigateToTableViewCreatingPage = (tableId: number) => {
        const targetTable = getDataTable(tableId);
        navigate('/project-editor', {
            state: {
                type: EditorTypeEnum.DataTable,
                dataTableEditorMode: DataTableEditorModeEnum.CreateView,
                dataTables: [
                    {
                        id: tableId,
                        name: targetTable.name,
                        originalTemplateName: targetTable.name,
                        columns: targetTable.columns,
                        views: [{ ...emptyTableView }],
                    },
                ],
                selectedDataTableId: tableId,
                mode: EditorModeEnum.CreateTemplate,
                additionalUrlToReturn: `?tab=${props.tabNumber}`,
            },
        });
    };

    return (
        <div className="data-table-templates">
            {isLoading ? (
                <DataLoader />
            ) : (
                <>
                    <div className="mb-3">
                        <strong>Current Templates</strong>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {
                                navigate('/project-editor', {
                                    state: {
                                        type: EditorTypeEnum.DataTable,
                                        mode: EditorModeEnum.CreateTemplate,
                                        additionalUrlToReturn: `?tab=${props.tabNumber}`,
                                    },
                                });
                            }}>
                            Add New Template
                        </Button>
                    </div>
                    <BootstrapTable
                        striped
                        bordered
                        bootstrap4
                        remote
                        keyField="rowId"
                        data={tableData}
                        columns={getColumns()}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            tooltipTargetId: tableTooltipTargetId,
                        })}
                        rowClasses={(row: DataTableAndTableViewFlatRow) => {
                            return row.rowType === TableRowType.DataTable
                                ? 'data-table-row'
                                : 'table-view-row';
                        }}
                        defaultSorted={[defaultSortDirection]}
                        onTableChange={handleTableChange}
                        noDataIndication="No Data available"
                    />
                </>
            )}

            {isTableDeleteConfirmationPopupVisible ? (
                <ConfirmationModal
                    isVisible={isTableDeleteConfirmationPopupVisible}
                    setIsVisible={setIsTableDeleteConfirmationPopupVisible}
                    header="Delete Data Table Confirmation"
                    confirmationText={`Are you sure you want to remove this Data Table template?`}
                    onConfirm={removeDataTableTemplate}
                    params={selectedTableTemplateId}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            ) : (
                []
            )}

            {isTableViewDeleteConfirmationPopupVisible ? (
                <ConfirmationModal
                    isVisible={isTableViewDeleteConfirmationPopupVisible}
                    setIsVisible={setIsTableViewDeleteConfirmationPopupVisible}
                    header="Delete Data Table View Confirmation"
                    confirmationText={`Are you sure you want to remove this Data Table View template?`}
                    onConfirm={({
                        tableTemplateId,
                        tableViewTemplateId,
                    }: any) =>
                        removeDataTableViewTemplate(
                            tableTemplateId,
                            tableViewTemplateId
                        )
                    }
                    params={{
                        tableTemplateId: selectedTableTemplateId,
                        tableViewTemplateId: selectedTableViewTemplateId,
                    }}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"
                />
            ) : (
                []
            )}
        </div>
    );
};
